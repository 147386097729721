/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */

import React, { useState, useEffect } from 'react';
// material-ui
import {
    Stack,
    IconButton,
    Button,
    Pagination,
    PaginationItem,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow
} from '@mui/material';

// project imports
import Chip from 'ui-component/extended/Chip';
import useAlertDialog from 'hooks/common/useAlertDialog';
import moment from 'moment';

/* queries */
import Spinner from 'components/spinner';
import FailureLoad from 'components/spinner/fail';
import useDebouncedSearch from 'hooks/common/useDebounceSearch';
import { Link, useNavigate } from 'react-router-dom';
import useSnackbar from 'hooks/common/useSnackbar';
import { CustomMainCard } from './index.styles';
import { EmailTemplate, EnquiryTemplate } from 'types/emailTemplate';
import MainCard from 'ui-component/cards/MainCard';

// assets
import { IconEdit } from '../../constants/icons';
import DeleteIcon from '@mui/icons-material/Delete';

import { ArrangementOrder1 } from 'types';
// import useTable from 'hooks/common/useTable';
import useTable from 'hooks/pageManagement/useTable';
import { FilterList } from './Filter';
import { headCells } from 'constants/enquiryTemplate';
import useGQL from 'hooks/useGQL';

// ==============================|| CUSTOMER LIST ||============================== //

const EnquiryFormTemplates = () => {
    const { TableContainer, TableHead } = useTable();
    const navigate = useNavigate();
    const { handleOpenSnackbar } = useSnackbar();
    const { getConfirmation } = useAlertDialog();

    const [debouncedSearch] = useDebouncedSearch((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event) {
            setSearch(event?.target.value);
        }
    });

    const { GET_ENQUIURY_TEMPLATES, DELETE_ENQUIRY_TEMP } = useGQL();
    const { error, loading, data, refetch } = GET_ENQUIURY_TEMPLATES();
    const [handleRemoveTemplate, { data: removeTemplateData }] = DELETE_ENQUIRY_TEMP();

    const [order, setOrder] = useState<ArrangementOrder1>('asc');
    const [orderBy, setOrderBy] = useState<string>('_id');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>({ limit: 10, skip: 0 });

    // const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [search, setSearch] = useState<string>('');
    const [rows, setRows] = useState<EnquiryTemplate[]>([]);
    const [count, setCount] = useState<number>(1);
    // const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>({ limit: 5, skip: 0 });

    useEffect(() => {
        if (data?.getAllEnquiryTemp) {
            setRows(data.getAllEnquiryTemp.enquiriesTemplates);
            setCount(data.getAllEnquiryTemp?.pagination?.total!);
        }
    }, [data]);

    useEffect(() => {
        console.log('removeTemplateData: ', removeTemplateData);
        if (removeTemplateData?.deleteEnquiryTemp) {
            handleOpenSnackbar({ message: removeTemplateData.deleteEnquiryTemp.message, alertType: 'success' });
        }
        handleRefetch();
    }, [removeTemplateData]);

    useEffect(() => {
        /* set page meta */
        const limit = rowsPerPage;
        const skip = page > 0 ? limit * page : 0;
        setPageMeta({ limit, skip });
        refetch({ input: { searchText: search, limit, skip, order, orderBy } });
    }, [rowsPerPage, page]);

    useEffect(() => {
        /* handle refetch here */
        handleRefetch();
    }, [orderBy, order, search]);

    /* handle refetch  */
    const handleRefetch = () => {
        const limit = rowsPerPage;
        const skip = page > 0 ? limit * page : 0;
        refetch({
            input: {
                searchText: search,
                limit: pageMeta.limit,
                skip,
                order,
                orderBy
            }
        });
        setPage(0);
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage - 1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setRowsPerPage(parseInt(event?.target.value, 10));
    };

    const handleDelete = async (id: string) => {
        try {
            await handleRemoveTemplate({ variables: { input: { _id: id } } });
        } catch (err: any) {
            handleOpenSnackbar({ message: removeTemplateData?.removeEmailTemplate?.message!, alertType: 'error' });
        }
    };

    const handleEdit = (id: string) => {
        navigate(`/enquiryform-templates/edit/${id}`);
    };

    const cardActions = <FilterList debouncedSearch={debouncedSearch} />;

    return (
        <MainCard title="Enquiry Form Templates" content={false} secondary={cardActions}>
            {/* <Button component={Link} to="/email-template/add" variant="outlined">
                Add Template
            </Button> */}
            <>
                {/* <FilterList debouncedSearch={debouncedSearch} /> */}
                {/* table */}
                {loading ? (
                    <Spinner />
                ) : error ? (
                    <FailureLoad />
                ) : (
                    <>
                        <TableContainer>
                            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                <TableHead headCells={headCells} />
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            {/* table cells render data */}
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left">{moment(row.createdAt).format('DD/MM/YYYY')}</TableCell>
                                            <TableCell align="left">{moment(row.updatedAt).format('DD/MM/YYYY')}</TableCell>
                                            <TableCell align="left">{row.status ? 'Active' : 'Disabled'}</TableCell>
                                            {/* <TableCell align="center">{getChip(row.status)}</TableCell> */}

                                            {/* table cells icon buttons */}
                                            <TableCell align="center">
                                                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                                    <Button
                                                        onClick={() => handleEdit(row._id)}
                                                        variant="outlined"
                                                        color="secondary"
                                                        size="medium"
                                                    >
                                                        <IconEdit color="primary" />
                                                    </Button>

                                                    <Button
                                                        onClick={() => handleDelete(row._id)}
                                                        variant="outlined"
                                                        color="secondary"
                                                        size="medium"
                                                    >
                                                        <DeleteIcon color="primary" />
                                                    </Button>

                                                    {/* <IconButton onClick={() => handleDelete(row._id)} color="error" size="large">
                                                    <DeleteIcon sx={{ fontSize: '1.3rem' }} />
                                                </IconButton> */}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* table pagination */}

                        <Pagination
                            count={Math.ceil(count / rowsPerPage)}
                            color="primary"
                            shape="rounded"
                            onChange={handleChangePage}
                            defaultPage={page + 1}
                            page={page + 1}
                            renderItem={(item) => <PaginationItem {...item} />}
                            // hidePrevButton={page === 0 ?? true}
                        />
                    </>
                )}
            </>
        </MainCard>
    );
};

export default EnquiryFormTemplates;
