import useGQL from 'hooks/useGQL';
import { createContext, useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import accountReducer from 'store/accountReducer';
import { LOGOUT } from 'store/actions';
import { InitialLoginContextProps } from 'types/auth';
import Loader from 'ui-component/Loader';

const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const AuthContext = createContext<null>(null);

export const AuthAccessProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigate = useNavigate();
    const { GET_ADMIN_PROFILE } = useGQL();
    const { data, error, refetch } = GET_ADMIN_PROFILE();
    const location = useLocation();

    useEffect(() => {
        if (error) {
            dispatch({ type: LOGOUT });
            navigate('/');
            localStorage.removeItem('accessToken');
        }
    }, [data, error, location.pathname]);

    useEffect(() => {
        refetch();
    }, [location.pathname]);

    // if (state.isInitialized !== undefined && !state.isInitialized) {
    //     return <Loader />;
    // }

    return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
};
